import { Stack, Tab, Tabs } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { MenuDropdown } from '@arcanna/generic';
import { useIntegrations, useJobsSummary } from 'src/data-access';
import { useMainNavItems } from './hooks';

function MainMenu() {
  const history = useHistory();
  const jobsList = useJobsSummary({});
  const integrationsList = useIntegrations();
  const { activeMenuItemId, mainNavItems } = useMainNavItems({
    jobCount: jobsList.data?.totalCount,
    integrationCount: integrationsList.data?.entries?.length
  });

  return (
    <Tabs value={activeMenuItemId}>
      {mainNavItems.map((navItem, index) => {
        if (navItem.subitems?.length) {
          return (
            <Tab
              disableRipple
              key={`header-item-${navItem.label}-${index}`}
              label={
                <MenuDropdown
                  menuItems={navItem.subitems.map((subItem) => ({
                    label: (
                      <Link to={subItem.path ?? ''}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          {subItem.icon}
                          <span>{subItem.label}</span>
                        </Stack>
                      </Link>
                    )
                  }))}
                  hasIcon={false}
                  anchorXPosition="left"
                  className="menu-dropdown"
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    {navItem.icon}
                    {navItem.label}
                  </Stack>
                </MenuDropdown>
              }
              value={navItem.id}
              // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
              onClick={() => history.push(navItem.path)}
              data-test-id={navItem.dataTestId}
            />
          );
        }

        return (
          <Tab
            disableRipple
            key={`header-item-${navItem.path}-${index}`}
            label={
              <Link to={navItem.path ?? ''}>
                <Stack direction="row" gap={1} alignItems="center">
                  {navItem.icon}
                  {navItem.label}
                </Stack>
              </Link>
            }
            data-test-id={navItem.dataTestId}
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
            onClick={() => history.push(navItem.path)}
            value={navItem.id}
          />
        );
      })}
    </Tabs>
  );
}

export default MainMenu;
