import { Avatar } from '@mui/material';
import { MenuDropdown } from '@arcanna/generic';
import { getStringInitials } from '@arcanna/utils';
import { useAuth } from 'src/components/pages/Main/Login/Auth.context';
import { useUserMenuItems } from './hooks';

function UserMenu() {
  const { state: authState } = useAuth();
  const menuItems = useUserMenuItems();

  return authState.isLoggedIn ? (
    <MenuDropdown menuItems={menuItems} className="right-menu">
      <Avatar data-test-id="user-menu-dropdown">{getStringInitials(authState.customerName)}</Avatar>
    </MenuDropdown>
  ) : (
    <></>
  );
}

export default UserMenu;
