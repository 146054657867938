/* eslint-disable max-len */
function Tool02() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_20830_8876)">
        <path
          d="M3 3L5.25 5.25M3 3H1.5L1 1.5L1.5 1L3 1.5V3ZM9.62949 1.37051L8.31569 2.68431C8.11768 2.88232 8.01867 2.98133 7.98158 3.09549C7.94895 3.19591 7.94895 3.30409 7.98158 3.40451C8.01867 3.51867 8.11768 3.61768 8.31569 3.81569L8.43432 3.93431C8.63232 4.13232 8.73133 4.23133 8.84549 4.26842C8.94591 4.30105 9.05409 4.30105 9.15451 4.26842C9.26867 4.23133 9.36768 4.13232 9.56569 3.93432L10.7946 2.70536C10.927 3.02744 11 3.3802 11 3.75C11 5.26878 9.76878 6.5 8.25 6.5C8.0669 6.5 7.88797 6.48211 7.71488 6.44797C7.47181 6.40003 7.35027 6.37606 7.27659 6.3834C7.19826 6.39121 7.15966 6.40295 7.09026 6.44009C7.02497 6.47503 6.95949 6.54051 6.82851 6.67149L3.25 10.25C2.83579 10.6642 2.16421 10.6642 1.75 10.25C1.33579 9.83578 1.33579 9.16421 1.75 8.74999L5.32851 5.17149C5.45949 5.04051 5.52497 4.97503 5.55991 4.90974C5.59705 4.84034 5.60879 4.80174 5.6166 4.72341C5.62394 4.64973 5.59997 4.52819 5.55203 4.28512C5.5179 4.11203 5.5 3.9331 5.5 3.75C5.5 2.23122 6.73122 1 8.25 1C8.75275 1 9.22399 1.13491 9.62949 1.37051ZM6.00003 7.49997L8.75 10.2499C9.16421 10.6642 9.83578 10.6642 10.25 10.2499C10.6642 9.83573 10.6642 9.16416 10.25 8.74995L7.98766 6.48765C7.82751 6.47249 7.67135 6.4436 7.52039 6.40217C7.32586 6.34877 7.11247 6.38753 6.96983 6.53017L6.00003 7.49997Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20830_8876">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Tool02;
