/* eslint-disable max-len */
function ArcannaLogoWhite() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M12.3333 3.46668C12.3333 3.87169 12.005 4.20001 11.6 4.20001C11.195 4.20001 10.8667 3.87169 10.8667 3.46668C10.8667 3.06167 11.195 2.73334 11.6 2.73334C12.005 2.73334 12.3333 3.06167 12.3333 3.46668Z"
        fill="#F0F6FC"
      />
      <path
        d="M11.6 4.80001C12.005 4.80001 12.3334 5.12834 12.3334 5.53335V12.3333C12.3334 12.3671 12.3311 12.4004 12.3266 12.4329C12.3695 12.7422 12.2101 13.0564 11.9129 13.1966C11.5466 13.3694 11.1096 13.2125 10.9368 12.8462L8.2734 7.19985L5.66567 12.8411C5.49573 13.2087 5.05994 13.3689 4.69231 13.199C4.32468 13.0291 4.16443 12.5933 4.33437 12.2256L7.52915 5.31448C7.81887 4.68775 8.70847 4.68431 9.00302 5.30877L10.8667 9.25974V5.53335C10.8667 5.12834 11.195 4.80001 11.6 4.80001Z"
        fill="#F0F6FC"
      />
      <path
        d="M8.26667 12.0667C8.67168 12.0667 9 11.7383 9 11.3333C9 10.9283 8.67168 10.6 8.26667 10.6C7.86166 10.6 7.53333 10.9283 7.53333 11.3333C7.53333 11.7383 7.86166 12.0667 8.26667 12.0667Z"
        fill="#F0F6FC"
      />
      <path
        d="M4.84 0.5C2.44308 0.5 0.5 2.44308 0.5 4.84V13.16C0.5 15.5569 2.44308 17.5 4.84 17.5H13.16C15.5569 17.5 17.5 15.5569 17.5 13.16V4.84C17.5 2.44308 15.5569 0.5 13.16 0.5H4.84Z"
        stroke="#2A3247"
      />
    </svg>
  );
}

export default ArcannaLogoWhite;
