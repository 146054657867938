import { BoxProps, Stack, Typography } from '@mui/material';
import StyledStateChip from './StyledStateChip.styles';
import React from 'react';

type TStateChipProps = {
  color: string;
  text: string;
  size?: 'small' | 'medium' | 'large';
  icon?: React.ReactNode;
  dataTestId?: string;
  textDirection?: 'rtl' | 'ltr';
} & BoxProps;

function StateChip({ color, text, size = 'small', textDirection = 'rtl', icon, dataTestId, ...otherProps }: TStateChipProps) {
  return (
    <StyledStateChip color={color} size={size} {...otherProps}>
      <Typography
        component="span"
        variant="inherit"
        color="inherit"
        textOverflow="ellipsis"
        overflow="hidden"
        sx={{
          direction: textDirection
        }}
        data-test-id={dataTestId}
      >
        {textDirection === 'rtl' ? <>&lrm;</> : <></>}
        {text}
      </Typography>
      {icon && (
        <Stack className="state-chip__icon" justifyContent="center" sx={{ marginLeft: '5px' }}>
          {icon}
        </Stack>
      )}
    </StyledStateChip>
  );
}

export default StateChip;
