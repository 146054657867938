import { Stack } from '@mui/material';
import StyledHeader from './StyledHeader.styles';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';
import siscaleLogo from 'src/themes/siscale/logoArcanna.png';
import orangeLogo from 'src/themes/orange/logo.png';
import HelpMenu from './components/HelpMenu';
import UserMenu from './components/UserMenu';
import getBranding from 'src/_srcMUI/config/branding/getBranding';
import MainMenu from './components/MainMenu';
import IdeaBox from './components/IdeaBox';

function Header() {
  const history = useHistory();
  const activeBrand = getBranding().activeBrand;

  return (
    <StyledHeader>
      <Stack direction="row" alignItems="flex-end">
        <div className="logo-wrapper">
          <img
            src={activeBrand === 'orange' ? orangeLogo : siscaleLogo}
            style={{ cursor: 'pointer' }}
            height={40}
            alt={activeBrand === 'orange' ? 'Orange' : 'Siscale'}
            onClick={() => history.push(config.routes.homepage)}
            data-test-id="logo"
          />
        </div>
        <MainMenu />
      </Stack>
      <Stack direction="row" alignItems="center" gap={3}>
        <IdeaBox />
        {activeBrand !== 'orange' && <HelpMenu />}
        <UserMenu />
      </Stack>
    </StyledHeader>
  );
}

export default Header;
