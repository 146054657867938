import { Label } from '@arcanna/generic';
import { Stack, StackProps, Switch, useTheme } from '@mui/material';
import { useMemo } from 'react';
import * as React from 'react';
import { useFeedbackEngineStore } from 'src/_srcMUI/shared/components/FeedbackEngine/store/FeedbackEngineStore';
import { useApp } from 'src/components/App/App.context';
import { JobCategory } from 'src/components/pages/Main/Jobs/helper';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';

type TOverwriteConsensusModeSwitchProps = {
  jobCategory: number | undefined;
} & StackProps;

function OverwriteConsensusModeSwitch({ jobCategory, ...otherProps }: TOverwriteConsensusModeSwitchProps) {
  const { palette } = useTheme();

  const isOverwriteMode = useFeedbackEngineStore((store) => store.isOverwriteMode);
  const setIsOverwriteMode = useFeedbackEngineStore((store) => store.setIsOverwriteMode);

  const { hasAccessFor } = useApp();
  const hasConsensusOverwritePermission = useMemo(() => hasAccessFor([permissions.feedbackOverwriteUpdate]), [hasAccessFor]);
  const isRCAJob = useMemo(() => jobCategory === JobCategory.RCA, [jobCategory]);

  if (isRCAJob || !hasConsensusOverwritePermission) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} {...otherProps}>
      <Switch
        checked={isOverwriteMode}
        onChange={() => setIsOverwriteMode(!isOverwriteMode)}
        size="small"
        data-test-id={'overwrite-consensus-btn'}
      />

      <Label
        text="Overwrite consensus mode"
        // eslint-disable-next-line max-len
        tooltipText="Enable this if you want to overwrite the consensus mechanism and freeze the decisions that will be used in training Arcanna."
        textProps={{
          color: isOverwriteMode ? palette.secondary[100] : palette.secondary[300]
        }}
      />
    </Stack>
  );
}

export default OverwriteConsensusModeSwitch;
