import { Box, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment/moment';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { AccuracyMetricsEntry } from '@arcanna/models/Overview';
import GenericOverviewCard from '../GenericOverviewCard';
import ArcannaDecisions from '../ArcannaDecisions';
import { formattedData } from './utils';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import OverviewNoFeedbackState from '../OverviewNoFeedbackState';

type TAccuracyMetricsProps = {
  data: AccuracyMetricsEntry[];
  isLoading: boolean;
  showDecisions?: boolean;
  sx?: SxProps<Theme>;
};

function ArcannaAccuracy({ data, isLoading, showDecisions = true, sx }: TAccuracyMetricsProps) {
  const { palette } = useTheme();

  const getCustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
    const date = moment(props?.payload?.[0]?.payload.fullDate).format('MM-DD-YYYY HH:mm');

    return (
      <Box sx={{ backgroundColor: palette.secondary[1000], borderRadius: '8px', width: '200px' }}>
        <Typography
          sx={{ padding: '4px 8px', borderBottom: `1px solid  ${palette.secondary[800]}` }}
          variant={'subtitle1'}
          color={palette.secondary[100]}
        >
          {date}
        </Typography>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          sx={{
            borderLeft: '3px solid ' + palette.info.light,
            borderBottom: `1px solid ${palette.secondary[800]}`,
            padding: '4px 8px'
          }}
        >
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            Accuracy
          </Typography>
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            {props?.payload?.[0]?.payload.accuracy}%
          </Typography>
        </Stack>
        <Stack
          justifyContent={'space-between'}
          direction={'row'}
          sx={{
            borderLeft: '3px solid ' + palette.warning.light,
            borderBottom: `1px solid ${palette.secondary[800]}`,
            padding: '4px 8px'
          }}
        >
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            F1-score
          </Typography>
          <Typography variant={'subtitle1'} color={palette.secondary[100]}>
            {props?.payload?.[0]?.payload.f1_score}%
          </Typography>
        </Stack>
        <Box sx={{ height: '24px' }} />
      </Box>
    );
  };

  return (
    <GenericOverviewCard
      title="Overall Accuracy and F1-Score"
      // eslint-disable-next-line max-len
      infoTooltipText="This timeseries shows how often your team has agreed or disagreed with Arcanna's decisions over time."
      isLoading={isLoading}
      minHeight="314px"
      sx={sx}
    >
      {data.length > 0 ? (
        <Stack direction={'row'} gap={'16px'} flexWrap={'wrap'}>
          <Stack direction="row" sx={{ width: '100%' }}>
            <Stack
              direction="column"
              sx={{
                width: showDecisions ? '40%' : '100%',
                borderRight: showDecisions ? `1px solid ${palette.secondary[800]}` : 0
              }}
            >
              <Stack direction={'row'} gap={'24px'} marginBottom={'20px'}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '100%',
                      backgroundColor: palette.info.light,
                      marginRight: '8px'
                    }}
                  />
                  <Typography variant="subtitle2" fontWeight={400} color={palette.secondary[100]} marginRight={'4px'}>
                    Accuracy:
                  </Typography>
                  <Typography fontWeight={600} data-test-id={'accuracy-value'}>
                    {data.slice(-1)[0]?.accuracy}%
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'}>
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '100%',
                      backgroundColor: palette.warning.light,
                      marginRight: '8px'
                    }}
                  />
                  <Typography variant="subtitle2" fontWeight={400} color={palette.secondary[100]} marginRight={'4px'}>
                    F1-score:
                  </Typography>
                  <Typography variant={'h4'} data-test-id={'f1-score-value'}>
                    {data.slice(-1)[0]?.f1_score}%
                  </Typography>
                </Stack>
              </Stack>
              <ResponsiveContainer height={200}>
                <LineChart data={formattedData<AccuracyMetricsEntry[]>(data)} margin={{ top: 0, right: 40, left: 0, bottom: 0 }}>
                  <XAxis
                    interval={Math.round((data?.length || 0) / 7)}
                    dataKey={'date'}
                    stroke={palette.secondary[400]}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={10}
                    tick={{ fontSize: '12px' }}
                  />
                  <YAxis
                    width={70}
                    tick={{ fontSize: '12px' }}
                    tickLine={false}
                    axisLine={false}
                    domain={[0, 100]}
                    stroke={palette.secondary[400]}
                    tickFormatter={(value) => `${value}%`}
                    tickMargin={20}
                  />
                  <CartesianGrid stroke={palette.secondary[800]} vertical={false} />
                  <Line type="monotone" dataKey={'accuracy'} stroke={palette.info.light} dot={false} strokeWidth={2} />
                  <Line type="monotone" dataKey={'f1_score'} stroke={palette.warning.light} dot={false} strokeWidth={2} />
                  <Tooltip content={getCustomTooltip} cursor={false} />
                </LineChart>
              </ResponsiveContainer>
            </Stack>
            {showDecisions && <ArcannaDecisions />}
          </Stack>
        </Stack>
      ) : (
        <OverviewNoFeedbackState />
      )}
    </GenericOverviewCard>
  );
}

export default ArcannaAccuracy;
