import { Drawer, Spinner } from '@arcanna/generic';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import ModelHistoryInsights from './components/ModelHistoryInsights';
import ModelHistoryConfusionMatrix from './components/ModelHistoryConfusionMatrix';
import { Stack, Typography } from '@mui/material';

type TModelHistoryPerformanceDrawerProps = {
  isOpen: boolean;
  handleClose: () => void;
  AIModel: ModelHistory;
};

function ModelHistoryPerformanceDrawer({ isOpen, handleClose, AIModel }: TModelHistoryPerformanceDrawerProps) {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      header={`${AIModel.modelFriendlyName ?? AIModel.modelId} Performance`}
    >
      <Stack gap={4}>
        {AIModel.newModelMetrics.recomputeMetricsFlag ? (
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Spinner sx={{ background: 'transparent', transform: 'scale(.7)' }} />
            <Typography variant="subtitle1">Recomputing</Typography>
          </Stack>
        ) : (
          <>
            <ModelHistoryInsights AIModel={AIModel} />
            <ModelHistoryConfusionMatrix AIModel={AIModel} />
          </>
        )}
      </Stack>
    </Drawer>
  );
}

export default ModelHistoryPerformanceDrawer;
