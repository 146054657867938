import { TFunction } from 'react-i18next';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { config } from 'src/config';
import { ReactNode } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { DashboardsIcon, KibanaIcon, ManagementIcon, ReportIcon, ClientDataManagementIcon } from 'src/themes/orange/icons';
import getBranding from 'src/_srcMUI/config/branding/getBranding';
import { Icon, EIcon } from '@arcanna/generic';

type TMenuItem = {
  id: string;
  permissions: (typeof permissions)[keyof typeof permissions][];
  path?: string;
  subitems?: Omit<TMenuItem, 'subitems' | 'id'>[];
  label: string;
  icon?: ReactNode;
  dataTestId?: string;
};

type TGetMenuItemsArgs = {
  jobCount: number;
  integrationCount: number;
  t: TFunction;
};

export const getMenuItems = (props: TGetMenuItemsArgs): TMenuItem[] => {
  const { activeBrand } = getBranding();

  if (activeBrand === 'orange') {
    return getOrangeMenuItems(props);
  }

  return getSiscaleMenuItems(props);
};

export const getSiscaleMenuItems = ({ jobCount, integrationCount, t }: TGetMenuItemsArgs): TMenuItem[] => [
  {
    id: 'homepage',
    permissions: [],
    path: config.routes.homepage,
    label: t('dashboard'),
    icon: <Icon name={EIcon.Home} />,
    dataTestId: 'dashboard'
  },
  {
    id: 'jobs',
    permissions: [permissions.jobRead],
    path: config.routes.jobsAll,
    label: t('aijobs', { noJobs: jobCount }),
    icon: <Icon name={EIcon.Job} />,
    dataTestId: 'aijobs'
  },
  {
    id: 'integrations',
    permissions: [permissions.integrationRead],
    path: config.routes.integrations.base,
    label: t('integrations', { noIntegrations: integrationCount }),
    icon: <Icon name={EIcon.Integration} />,
    dataTestId: 'integrations'
  }
  // {
  //   id: 'arcannaChat',
  //   permissions: [permissions.userRead],
  //   path: config.routes.arcannaChat,
  //   label: 'Arcanna',
  //   icon: <Icon name={EIcon.Stars01} />,
  //   dataTestId: 'arcanna-chat'
  // }
];

export const getOrangeMenuItems = ({ jobCount, integrationCount, t }: TGetMenuItemsArgs): TMenuItem[] => [
  {
    id: 'homepage',
    permissions: [],
    path: config.routes.homepage,
    label: t('home'),
    icon: <HomeOutlined />,
    dataTestId: 'homepage'
  },
  {
    id: 'dashboards',
    permissions: [],
    label: t('dashboards'),
    icon: <DashboardsIcon />,
    subitems: [
      {
        permissions: [permissions.useCaseRead, permissions.chartsRead],
        path: config.routes.charts,
        label: t('charts'),
        icon: <DashboardsIcon />
      }
    ],
    dataTestId: 'dashboards'
  },
  {
    id: 'csvExports',
    permissions: [permissions.exportRead],
    path: config.routes.exportCsv,
    label: t('csvExports'),
    icon: <ReportIcon />,
    dataTestId: 'csvExports'
  },
  {
    id: 'reporting',
    permissions: [permissions.reportRead],
    path: config.routes.reporting,
    label: t('reporting'),
    icon: <ReportIcon />,
    dataTestId: 'reporting'
  },
  {
    id: 'management',
    permissions: [],
    label: t('management'),
    icon: <ManagementIcon />,
    subitems: [
      {
        permissions: [permissions.jobRead],
        path: config.routes.jobsAll,
        label: t('aijobs', { noJobs: jobCount }),
        icon: <Icon name={EIcon.Job} />
      },
      {
        permissions: [permissions.integrationRead],
        path: config.routes.integrations.base,
        label: t('integrations', { noIntegrations: integrationCount }),
        icon: <Icon name={EIcon.Integration} />
      },
      {
        permissions: [permissions.csvRead, permissions.csvUpdate, permissions.csvDelete],
        path: config.routes.csv,
        label: t('clientDataManagement'),
        icon: <ClientDataManagementIcon />
      },
      {
        permissions: [permissions.kibanaLogin],
        path: config.routes.kibana,
        label: t('goToKibana'),
        icon: <KibanaIcon />
      }
    ],
    dataTestId: 'management'
  }
];
