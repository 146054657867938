/* eslint-disable max-len */
function MessagePlusCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10.4165 12.0833V7.08333M7.91652 9.58333H12.9165M10.4165 16.6667C14.3285 16.6667 17.4998 13.4953 17.4998 9.58333C17.4998 5.67132 14.3285 2.5 10.4165 2.5C6.5045 2.5 3.33318 5.67132 3.33318 9.58333C3.33318 10.375 3.46306 11.1363 3.70266 11.8472C3.79282 12.1147 3.83791 12.2484 3.84604 12.3512C3.85407 12.4527 3.848 12.5238 3.82289 12.6225C3.79747 12.7223 3.74135 12.8262 3.62909 13.034L2.26605 15.557C2.07162 15.9168 1.97441 16.0968 1.99617 16.2356C2.01512 16.3566 2.08631 16.4631 2.19083 16.5269C2.31083 16.6001 2.51427 16.579 2.92113 16.537L7.18865 16.0958C7.31789 16.0825 7.3825 16.0758 7.4414 16.0781C7.49933 16.0803 7.54022 16.0857 7.59671 16.0987C7.65414 16.112 7.72637 16.1398 7.87081 16.1954C8.66085 16.4998 9.5192 16.6667 10.4165 16.6667Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MessagePlusCircle;
