/* eslint-disable max-len */
function AIUpscaleSpark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.42843 14.2856C8.32893 14.2856 9.2206 14.1082 10.0525 13.7636C10.8845 13.419 11.6404 12.9139 12.2772 12.2772C12.9139 11.6404 13.419 10.8845 13.7636 10.0525C14.1082 9.2206 14.2856 8.32893 14.2856 7.42843C14.2856 6.52794 14.1082 5.63626 13.7636 4.80432C13.419 3.97237 12.9139 3.21644 12.2772 2.5797C11.6404 1.94296 10.8845 1.43786 10.0525 1.09326C9.2206 0.748654 8.32893 0.571289 7.42843 0.571289C5.6098 0.571289 3.86566 1.29374 2.5797 2.5797C1.29374 3.86566 0.571289 5.6098 0.571289 7.42843C0.571289 9.24706 1.29374 10.9912 2.5797 12.2772C3.86566 13.5631 5.6098 14.2856 7.42843 14.2856Z"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2744 12.2744L15.4287 15.4287"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.94238 7.80372C3.52181 7.73058 3.52181 7.12715 3.94238 7.054C4.68654 6.92404 5.37519 6.57554 5.92064 6.05289C6.46608 5.53024 6.84364 4.85709 7.00524 4.11915L7.03038 4.00372C7.12181 3.58772 7.71267 3.58543 7.80752 4.00029L7.83838 4.13515C8.00628 4.87038 8.38783 5.53955 8.93505 6.05851C9.48226 6.57746 10.1707 6.92301 10.9138 7.05172C11.3367 7.126 11.3367 7.73172 10.9138 7.806C10.1709 7.93492 9.48272 8.28056 8.93572 8.7995C8.38872 9.31844 8.00734 9.9875 7.83952 10.7226L7.80867 10.8574C7.71381 11.2723 7.12295 11.2689 7.03152 10.854L7.00638 10.7397C6.84511 10.0012 6.46756 9.32744 5.92186 8.80434C5.37617 8.28124 4.68705 7.93364 3.94238 7.80372Z"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AIUpscaleSpark;
