import { EIcon, Icon } from '@arcanna/generic';
import { QueryParams } from '@arcanna/utils';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';

type AIInvestigateButtonProps = {
  jobId: string | number;
  entityId: string[];
  entityType: 'bucket' | 'alert';
};

function AIInvestigateButton({ jobId, entityId, entityType }: AIInvestigateButtonProps) {
  const history = useHistory();

  const navigateToArcannaChat = useCallback(() => {
    const filtersQP = QueryParams.filtersToParams({
      jobId,
      entityIds: entityId?.join(','),
      entityType
    });

    const url = filtersQP ? `${config.routes.arcannaChat}?${filtersQP}` : config.routes.arcannaChat;

    history.push(url);
  }, [entityId, entityType, history, jobId]);

  return (
    <Button
      sx={{
        minHeight: 'unset',
        minWidth: 'unset',
        width: '200px',
        height: '24px'
      }}
      variant="contained"
      color="secondary"
      onClick={navigateToArcannaChat}
      startIcon={<Icon name={EIcon.ArcannaLogoWhite} sx={{ fontSize: '24px' }} />}
    >
      Start Investigation
    </Button>
  );
}

export default AIInvestigateButton;
