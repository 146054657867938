import { Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EIcon, Icon } from '@arcanna/generic';
import { useState, useRef, useMemo } from 'react';
import Joi from 'joi';
import { CustomLabelPillMenu } from '../../forms/RHFPillField/components';
import { customLabelColors } from '../../forms/RHFPillField/components/CustomLabelPillMenu/CustomLabelPillMenu.constants';
import { getCustomLabelsFromJobs } from '../../../../components/pages/Main/Jobs/Feedback/Flow/SelectFeedback';
import { useJobInfo } from '../../../../data-access';
import useAddCustomLabel from '@arcanna/requests/Jobs/useAddCustomLabel';
import { constructAddCustomLabelRequest } from '@arcanna/models/Jobs/AddCustomLabelRequest';
import { getPillId } from '../../forms/RHFPillField/RHFPillField.utils';

type TAddCustomLabelButtonProps = {
  jobId: number;
  shrinkedView?: boolean;
  handleAutocompleteClose?: () => void;
};

function AddCustomLabelButton({ jobId, shrinkedView = false, handleAutocompleteClose }: TAddCustomLabelButtonProps) {
  const { data: jobInfoData } = useJobInfo(jobId);

  const allLabelPills = useMemo(() => (jobInfoData ? getCustomLabelsFromJobs([jobInfoData]) : []), [jobInfoData]);

  const { mutate: addCustomLabel } = useAddCustomLabel({ jobId });

  const { t } = useTranslation(['common', 'feedback']);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [newLabel, setNewLabel] = useState<string>('');
  const [selectedPillIndex, setSelectedPillIndex] = useState<number>(-1);
  const [customError, setCustomError] = useState<string>('');
  const addPillButtonRef = useRef(null);

  const handleAddCustomLabel = () => {
    setSelectedColor(customLabelColors[0]);
    setNewLabel('');
    setAnchorEl(addPillButtonRef.current);
    setSelectedPillIndex(allLabelPills?.length ?? -1);
  };

  const handleClose = () => {
    setSelectedColor('');
    setNewLabel('');
    setCustomError('');
    setAnchorEl(null);
    setSelectedPillIndex(-1);
  };

  const labelSchema = Joi.string()
    .required()
    .trim()
    .min(3)
    .messages({
      'string.empty': t('decisionLabelRequired'),
      'any.required': t('decisionLabelRequired'),
      'string.min': t('decisionLabelMinLength')
    });

  const validateAndSet = (value: string) => {
    const { error } = labelSchema.validate(value);
    setCustomError(error ? error.details[0].message : '');
    setNewLabel(value);
  };

  const isLabelUnique = (value: string) => {
    if (selectedPillIndex < 0) {
      return true;
    }
    const currentWorkingPill = allLabelPills[selectedPillIndex];
    return allLabelPills.every((pill) => pill.name !== value.trim() || pill.name === currentWorkingPill?.name);
  };

  const handleSave = () => {
    if (customError) {
      return;
    }

    if (!isLabelUnique(newLabel)) {
      setCustomError(t('decisionLabelMustBeUnique'));

      return;
    }

    const customLabel = {
      id: getPillId(allLabelPills),
      name: newLabel.trim(),
      hexColor: selectedColor
    };

    const payload = constructAddCustomLabelRequest({
      customLabel: customLabel
    });

    addCustomLabel(payload);

    handleClose();

    if (handleAutocompleteClose) {
      handleAutocompleteClose();
    }
  };

  return (
    <>
      {shrinkedView !== undefined && shrinkedView ? (
        <IconButton
          ref={addPillButtonRef}
          size="small"
          variant="tertiary"
          onClick={() => {
            handleAddCustomLabel();
          }}
          data-test-id={'add-label-icon-btn'}
        >
          <Icon name={EIcon.Plus} fontSize="inherit" />
        </IconButton>
      ) : (
        <Button
          ref={addPillButtonRef}
          size="small"
          variant="tertiary"
          endIcon={<Icon name={EIcon.Plus} />}
          onClick={() => {
            handleAddCustomLabel();
          }}
          data-test-id={'add-decision-btn'}
        >
          {t('addDecision')}
        </Button>
      )}
      <CustomLabelPillMenu
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleClose={handleClose}
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        newLabel={newLabel}
        customError={customError}
        handleSave={handleSave}
        validateAndSet={validateAndSet}
      />
    </>
  );
}

export default AddCustomLabelButton;
