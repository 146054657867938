import { Stack, Theme, Tooltip, Typography } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { ModelHistory } from 'src/components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import ModelHistoryActionColumn from './components/ModelHistoryActionColumn';
import { ReactNode } from 'react';
import { formatPercentage } from '../../../Overview/Overview.utils';
import { Spinner } from '@arcanna/generic';
import ModelHistoryLastRecomputedColumn from './components/ModelHistoryLastRecomputedColumn';

export const getColumns = (currentAIModel: string | null, theme: Theme) => {
  const columnHelper = createColumnHelper<ModelHistory>();

  const renderColumnValue = ({
    isRevertible,
    value,
    dataTestId
  }: {
    isRevertible: boolean;
    value: ReactNode;
    dataTestId?: string;
  }) => (
    <Tooltip
      placement="bottom"
      enterDelay={1000}
      enterNextDelay={1000}
      componentsProps={{
        tooltip: {
          sx: {
            background: theme.palette.secondary[600]
          }
        }
      }}
      title={isRevertible ? '' : 'This model version is no longer available'}
    >
      <Stack component="span" data-test-id={`${dataTestId}-${typeof value === 'string' ? value : ''}`}>
        {value}
      </Stack>
    </Tooltip>
  );

  const renderLoadingValue = ({ message, isLoading, children }: { message: string; isLoading: boolean; children: ReactNode }) =>
    isLoading ? (
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Spinner sx={{ background: 'transparent', transform: 'scale(.7)' }} />
        <Typography variant="caption">{message}</Typography>
      </Stack>
    ) : (
      children
    );

  return [
    columnHelper.accessor('modelId', {
      id: 'modelId',
      header: () => (
        <Typography variant="subtitle2" fontWeight={500} marginLeft={'20px'}>
          AI Model Name
        </Typography>
      ),
      cell: ({ row }) =>
        renderColumnValue({
          isRevertible: row.original.isRevertible ?? false,
          value: (
            <Stack direction="row" alignItems="center" gap={1}>
              <Stack
                sx={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: row.original.modelId === currentAIModel ? theme.palette.primary.main : 'transparent'
                }}
              />
              <Typography variant="caption">{row.original?.modelFriendlyName ?? row.original.modelId}</Typography>
            </Stack>
          ),
          dataTestId: `model-id-${row.original?.modelFriendlyName ?? row.original?.modelId}`
        }),
      size: 220,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('trainDate', {
      id: 'trainDate',
      header: 'Trained On',
      cell: ({ row, cell }) =>
        renderColumnValue({
          isRevertible: row.original.isRevertible ?? false,
          value: moment(cell.getValue()).format('MMM DD, YYYY HH:mm'),
          dataTestId: `${row.original?.modelFriendlyName ?? row.original?.modelId}-train-date`
        }),
      size: 120,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('newModelMetrics.accuracy', {
      id: 'accuracy',
      header: 'Model Accuracy',
      cell: ({ row, cell }) =>
        renderColumnValue({
          isRevertible: row.original.isRevertible ?? false,
          value: renderLoadingValue({
            message: 'Recomputing',
            isLoading: row.original.newModelMetrics.recomputeMetricsFlag,
            children: formatPercentage(cell.getValue(), 'No Accuracy Yet')
          }),
          dataTestId: `${row.original?.modelFriendlyName ?? row.original?.modelId}-accuracy`
        }),
      size: 120,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('newModelMetrics.f1Score', {
      id: 'f1Score',
      header: 'F1 Score',
      cell: ({ row, cell }) =>
        renderColumnValue({
          isRevertible: row.original.isRevertible ?? false,
          value: renderLoadingValue({
            message: 'Recomputing',
            isLoading: row.original.newModelMetrics.recomputeMetricsFlag,
            children: formatPercentage(cell.getValue(), 'No Accuracy Yet')
          }),
          dataTestId: `${row.original?.modelFriendlyName ?? row.original?.modelId}-f1-score`
        }),
      size: 120,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.accessor('newModelMetrics.lastRecomputeMetricsTimestamp', {
      id: 'lastRecomputeMetricsTimestamp',
      header: 'Last Recomputed',
      cell: ({ row }) =>
        renderColumnValue({
          isRevertible: row.original.isRevertible ?? false,
          value: renderLoadingValue({
            message: 'Updating',
            isLoading: row.original.newModelMetrics.recomputeMetricsFlag,
            children: <ModelHistoryLastRecomputedColumn AIModel={row.original} />
          }),
          dataTestId: `${row.original?.modelFriendlyName ?? row.original?.modelId}-last-recomputed`
        }),
      size: 140,
      enableHiding: false,
      enableResizing: false
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({ row }) => <ModelHistoryActionColumn AIModel={row.original} currentAIModelId={currentAIModel} />,
      size: 30
    })
  ];
};
